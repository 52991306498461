import React from 'react';
import styles from './index.scss';
import CountTo from 'react-count-to';

export const Numbers = ({ number, graph, speed, classes }) => {
  const numberStyles = graph ? `graph-numbers` : `animated-numbers`;
  const percent = graph ? '' : '%';

  return (
    <div className={`number-container ${classes}`}>
      <div className={numberStyles}>
        <CountTo to={number} speed={speed} className="count-number" />
        <span className="percent">{percent}</span>
      </div>
    </div>
  );
};
