import React from 'react';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import { Row, Col } from 'react-flexbox-grid';

import safetyChart from 'images/hcp/pages/clinical-data/clinical-data-safety-chart.png';

export const SafetyData = props => {
  const marginStyle = {
    marginLeft: '0px',
    marginRight: '0px',
  };
  const paddingStyle = {
    paddingLeft: '0px',
    paddingRightt: '0px',
  };
  return (
    <CommonContainer classes="gray">
      <div id="safety">
        <ContentBlock>
          <h2 className="no-padding-bottom">
            <strong>SAFETY</strong> DATA
          </h2>
          <h3 className="sub-headline">
            SPRIX® (ketorolac tromethamine) was studied in patients enrolled in
            two placebo-controlled efficacy studies of acute pain following
            major surgery
          </h3>
          <div>
            <Row style={marginStyle}>
              <Col xs={12} md={8} style={paddingStyle}>
                <img className="safety-chart" src={safetyChart} />
              </Col>
              <Col xs={12} md={4}>
                <p className="safety-chart-copy">
                  Postoperative patients with adverse reactions observed at a
                  rate of 2% or greater and at least twice the incidence of the
                  placebo group
                </p>
              </Col>
            </Row>
          </div>
          <p className="mobile-copy-pad legal-chart-copy">
            In clinical studies, 1.5% of patients treated with SPRIX experienced
            serious adverse events of bleeding or hematoma at the operative site
            vs 0.4% treated with placebo (hematoma).
          </p>
          <p className="mobile-copy-pad legal-chart-copy">
            The most frequently reported adverse reactions were related to local
            symptoms, i.e., nasal discomfort or irritation. These reactions were
            generally mild and transient in nature.
          </p>
          <p className="blue-box">
            The total duration of use of SPRIX or any other ketorolac
            formulation should not exceed 5 days.
          </p>
        </ContentBlock>
      </div>
    </CommonContainer>
  );
};
