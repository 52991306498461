import React, { useContext, useState, useEffect } from 'react';
import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';

import { Rapid } from 'components/pages/clinical-data/Rapid';
import { LessPain } from 'components/pages/clinical-data/LessPain';
import { LessOpiods } from 'components/pages/clinical-data/LessOpioids';
import { SafetyData } from 'components/pages/clinical-data/SafetyData';
import elementInView from 'libs/elementInView';

import { StudyBrownModal } from 'components/modals/StudyBrownModal';
import { StudySinglaModal } from 'components/modals/StudySinglaModal';

// import page style
import './clinical-data.scss';

const pageTitle = 'Clinical Data';
const pageDescription = '';

const ClinicalDataPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [didScrollToStudyOne, setDidScrollToStudyOne] = useState(false);
  const [studyOneValue, setStudyOneValue] = useState(0);
  const [studyOneBValue, setStudyOneBValue] = useState(0);
  const [didScrollToStudyTwo, setDidScrollToStudyTwo] = useState(false);
  const [studyTwoValue, setStudyTwoValue] = useState(0);
  const [studyTwoBValue, setStudyTwoBValue] = useState(0);
  const [rapidValue, setRapidValue] = useState(0);
  const [firstScroll, setFrstScroll] = useState(false);
  const [studyBrownOpen, setStudyBrownOpen] = useState(false);
  const [studySinglaOpen, setStudySinglaOpen] = useState(false);

  const handleStudyBrownClose = () => {
    setStudyBrownOpen(false);
  };

  const handleStudySinglaClose = () => {
    setStudySinglaOpen(false);
  };

  const handleStudyBrownOpen = () => {
    console.log('handleStudyBrownOpen');
    setStudyBrownOpen(true);
  };

  const handleStudySinglaOpen = () => {
    console.log('handleStudyBrownOpen');
    setStudySinglaOpen(true);
  };

  const handleScroll = () => {
    const studyOne = document.getElementById('study-one');
    const studyTwo = document.getElementById('study-two');
    const rapid = document.getElementById('rapid');

    // exit if undefined
    if (studyOne == null || studyTwo == null || rapid == null) {
      return;
    }
    if (elementInView(studyOne)) {
      setDidScrollToStudyOne(true);
      setStudyOneValue(20);
      setStudyOneBValue(18);
    } else {
      setDidScrollToStudyOne(false);
      setStudyOneValue(0);
      setStudyOneBValue(0);
    }

    if (elementInView(studyTwo)) {
      setDidScrollToStudyOne(true);
      setStudyTwoValue(36);
      setStudyTwoBValue(26);
    } else {
      setDidScrollToStudyTwo(false);
      setStudyTwoValue(0);
      setStudyTwoBValue(0);
    }

    if (elementInView(rapid)) {
      setRapidValue(45);
      setFrstScroll(true);
    } else {
      setRapidValue(0);
      setFrstScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout indication={siteIndications.hcp} className="hcp-clinical-data">
      <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
      <Rapid firstScroll={firstScroll} rapidValue={rapidValue} />
      <LessPain
        studyOneValue={studyOneValue}
        studyOneBValue={studyOneBValue}
        handleStudyBrown={handleStudyBrownOpen}
        handleStudySingla={handleStudySinglaOpen}
      />
      <LessOpiods
        studyTwoValue={studyTwoValue}
        studyTwoBValue={studyTwoBValue}
        handleStudyBrown={handleStudyBrownOpen}
        handleStudySingla={handleStudySinglaOpen}
      />
      <SafetyData />
      {studyBrownOpen && (
        <StudyBrownModal
          open={studyBrownOpen}
          closeModal={handleStudyBrownClose}
        />
      )}
      {studySinglaOpen && (
        <StudySinglaModal
          open={studySinglaOpen}
          closeModal={handleStudySinglaClose}
        />
      )}
    </Layout>
  );
};

export default ClinicalDataPage;
