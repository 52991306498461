import React from 'react';
import Modal from 'react-awesome-modal';

import './study-modal.scss';

export const StudySinglaModal = ({ open, closeModal }) => {
  return (
    <div className="study-modal-container">
      <Modal visible={open} onClickAway={() => closeModal()}>
        <div className="study-container">
          <div className="study-header">
            <div class="close" onClick={() => closeModal()}>
              ×
            </div>
          </div>
          <div className="study-content">
            <h2>Study 2 - Singla</h2>
            <p>
              A phase 3, randomized, double-blind, placebo-controlled study to
              evaluate the analgesic efficacy and tolerability of SPRIX use
              after abdominal surgery. Adult patients were randomly assigned to
              receive SPRIX 31.5 mg (n=214) or placebo (n=107) every 6 hours
              after surgery for 48 hours, then up to 4 times/day for up to 5
              days. Morphine sulfate via patient-controlled analgesia (PCA) was
              available through at least 48 hours in both groups as needed. When
              PCA was no longer required, oral analgesics such as
              hydrocodone/acetaminophen were permitted for pain as needed. After
              surgery, intravenous opioid was administered at the discretion of
              the investigator. Patients recorded pain intensity (PI) ratings
              using a Visual Analog Scale (VAS) of 0 (no pain) to 100 mm (worst
              pain). When PI ratings equaled at least 40 on the VAS, patients
              received SPRIX or placebo. Morphine use reduction was a secondary
              endpoint.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
