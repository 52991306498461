import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { Numbers } from 'components/Numbers';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';

import chart from 'images/hcp/pages/clinical-data/clinical-data-chart-bg-noclock.png';
import chartDataLines from 'images/hcp/pages/clinical-data/clinical-data-chart-lines.png';
import clockArrowHour from 'images/hcp/pages/clinical-data/clinical-data-chart-clock-arrow-hour.png';
import clockArrowMinutes from 'images/hcp/pages/clinical-data/clinical-data-chart-clock-arrow-minute.png';
import chartMobile from 'images/hcp/pages/clinical-data/clinical-data-rapid-graph-mobile.png';

export const Rapid = ({ firstScroll, rapidValue }) => {
  return (
    <div id="rapid">
      <CommonContainer classes="gray">
        <ContentBlock>
          <h1 className="no-padding-bottom">
            <span className="strong">RAPID</span> ABSORPTION
          </h1>
          <h3>
            SPRIX&reg; (ketorolac tromethamine) is rapidly absorbed through the
            nasal mucosa<sup>1</sup>
          </h3>
          <div className="chart-container">
            <Numbers
              number={firstScroll ? rapidValue : 45}
              graph
              speed={1000}
              classes={'hidden-mobile'}
            />
            <div className="chart-data chart-animation hidden-mobile">
              <img src={chartDataLines} />
            </div>
            <div className="clock-arrow-hour hidden-mobile">
              <img src={clockArrowHour} />
            </div>
            <div className="clock-arrow-minute hidden-mobile">
              <img src={clockArrowMinutes} />
            </div>
            <img src={chart} className="hidden-mobile" />
          </div>
          <img src={chartMobile} className="hidden-desktop chart-mobile" />
          <p>
            A phase 1, single-dose, 5-way crossover, randomized study (N=15)
            consisting of 5 periods of identical design, differing only in the
            allocate treatment and examination of the nares. In all periods,
            serial blood samples were taken up to 24 hours postdose.
            <sup>1</sup>
          </p>
          <p>
            SPRIX has been studied in patients with allergic rhinitis before and
            after administration of fluticasone and oxymetazoline. Neither
            medicine had any clinically significant effect on the PK
            characteristics of SPRIX.
            <sup>2</sup>
          </p>
          <p>Adapted with permission from McAleer et al.</p>
        </ContentBlock>
      </CommonContainer>
    </div>
  );
};
