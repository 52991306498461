import React from 'react';

import { Numbers } from 'components/Numbers';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import StudyBlock from 'components/study-block';

import study120 from 'images/hcp/pages/clinical-data/clinical-data-study1-20.png';
import study118 from 'images/hcp/pages/clinical-data/clinical-data-study1-18.png';
import study120mobile from 'images/hcp/pages/clinical-data/clinical-data-study1-20-mobile.png';
import study118mobile from 'images/hcp/pages/clinical-data/clinical-data-study1-18-mobile.png';

export const LessPain = ({
  studyOneValue,
  studyOneBValue,
  handleStudyBrown,
  handleStudySingla,
}) => {
  return (
    <div className="less-pain-container" id="proven">
      <CommonContainer>
        <ContentBlock>
          <h2 className="no-padding-bottom">
            <strong>LESS</strong> pain
          </h2>
          <h3 className="sub-headline">
            The efficacy of SPRIX® (ketorolac tromethamine) Nasal Spray was
            demonstrated by a statistically significant reduction in pain vs
            placebo in an acute pain study of post-abdominal or -orthopedic
            surgery patients and in a second study of post-abdominal surgery
            patients
            <sup>2,3,4,5</sup>
          </h3>
          <div id="study-one">
            <StudyBlock percentage={20} studyNum={1}>
              <Numbers
                number={studyOneValue}
                speed={1000}
                classes="hidden-mobile"
              />
              <img className="study-numbers hidden-mobile" src={study120} />
              <img
                className="study-numbers hidden-desktop"
                src={study120mobile}
              />
            </StudyBlock>
            <StudyBlock percentage={18} studyNum={2} color="dark-blue">
              <Numbers
                number={studyOneBValue}
                speed={1000}
                classes="hidden-mobile"
              />
              <img className="study-numbers hidden-mobile" src={study118} />
              <img
                className="study-numbers hidden-desktop"
                src={study118mobile}
              />
            </StudyBlock>
          </div>
          <p className="center">
            SPID48 = Summed Pain Intensity Difference over 48 hours
          </p>
          <div className="yellow-box-container">
            <div className="yellow-box" pl={2} onClick={handleStudyBrown}>
              <div className="full-click-container">
                <h4 className="view-study-title">View Study 1 - Brown</h4>
                <p>
                  A phase 3, randomized, double-blind, placebo-controlled study
                  to evaluate the analgesic efficacy and tolerability of single-
                  and multiple-dose SPRIX in major abdominal and orthopedic
                  surgery patients remaining in hospital for 2–5 days.
                </p>
                <p className="underline" href="#">
                  Click to View More
                </p>
              </div>
            </div>
            <div
              className="yellow-box margin-lt-16"
              onClick={handleStudySingla}
            >
              <div className="full-click-container">
                <h4 className="view-study-title">View Study 2 - Singla</h4>
                <p className="extra-pad">
                  A phase 3, randomized, double-blind, placebo-controlled study
                  to evaluate the analgesic efficacy and tolerability of SPRIX
                  use after abdominal surgery for up to 5 days.
                </p>
                <p className="underline" href="#">
                  Click to View More
                </p>
              </div>
            </div>
          </div>
        </ContentBlock>
      </CommonContainer>
    </div>
  );
};
