import React from 'react';
import { Col } from 'react-flexbox-grid';

import './index.scss';

const StudyBlock = ({ color, children }) => {
  // const studyBlockType = color == 'lt-blue' ? 'lt-blue' : 'dark-blue';
  return <div className="study-block-container">{children}</div>;
};
export default StudyBlock;
