import React from 'react';

import { Numbers } from 'components/Numbers';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import StudyBlock from 'components/study-block';

import study2Arrow from 'images/hcp/pages/clinical-data/clinical-data-study2-arrow.png';
import study236 from 'images/hcp/pages/clinical-data/clinical-data-study2-36.png';
import study236mobile from 'images/hcp/pages/clinical-data/clinical-data-study2-36-mobile.png';
import study226mobile from 'images/hcp/pages/clinical-data/clinical-data-study2-26-mobile.png';
import study226 from 'images/hcp/pages/clinical-data/clinical-data-study2-26.png';
export const LessOpiods = ({
  studyTwoValue,
  studyTwoBValue,
  handleStudyBrown,
  handleStudySingla,
}) => {
  return (
    <div className="less-opiod-container">
      <div className="less-opiod-inner-container">
        <CommonContainer>
          <ContentBlock>
            <h2 className="no-padding-bottom">
              <strong>LESS</strong> opioids
            </h2>
            <h3 className="sub-headline">
              Postoperative patients using SPRIX required less morphine vs the
              placebo group<sup>2,3,4</sup>
            </h3>
            <div id="study-two">
              <StudyBlock percentage={36} studyNum={1} color="lt-Blue">
                <div className="study2-arrow hidden-mobile">
                  <img src={study2Arrow} />
                </div>
                <Numbers
                  number={studyTwoValue}
                  speed={1000}
                  classes="hidden-mobile"
                />
                <img className="study-numbers hidden-mobile" src={study236} />
                <img
                  className="study-numbers hidden-desktop"
                  src={study236mobile}
                />
              </StudyBlock>
              <StudyBlock percentage={26} studyNum={2} color={'ltBlue'}>
                <div className="study2-arrow hidden-mobile">
                  <img src={study2Arrow} />
                </div>
                <Numbers
                  number={studyTwoBValue}
                  speed={1000}
                  classes="hidden-mobile"
                />
                <img className="study-numbers hidden-mobile" src={study226} />
                <img
                  className="study-numbers hidden-desktop"
                  src={study226mobile}
                />
              </StudyBlock>
            </div>
            <p className="center">
              *All patients had access to morphine sulfate (MS) by
              patient-controlled analgesia (PCA) beginning on Day 0.{' '}
            </p>
            <div className="yellow-box-container">
              <div
                col={6}
                className="yellow-box"
                pl={2}
                onClick={handleStudyBrown}
              >
                <div className="full-click-container">
                  <h4 className="view-study-title">View Study 1 - Brown</h4>
                  <p>
                    A phase 3, randomized, double-blind, placebo-controlled
                    study to evaluate the analgesic efficacy and tolerability of
                    single- and multiple-dose SPRIX in major abdominal and
                    orthopedic surgery patients remaining in hospital for 2–5
                    days.
                  </p>
                  <p className="underline" href="#">
                    Click to View More
                  </p>
                </div>
              </div>
              <div
                className="yellow-box margin-lt-16"
                onClick={handleStudySingla}
              >
                <div className="full-click-container">
                  <h4 className="view-study-title">View Study 2 - Singla</h4>
                  <p className="extra-pad">
                    A phase 3, randomized, double-blind, placebo-controlled
                    study to evaluate the analgesic efficacy and tolerability of
                    SPRIX use after abdominal surgery for up to 5 days.
                  </p>
                  <p className="underline" href="#">
                    Click to View More
                  </p>
                </div>
              </div>
            </div>
          </ContentBlock>
        </CommonContainer>
      </div>
    </div>
  );
};
