import React from 'react';
import Modal from 'react-awesome-modal';

import './study-modal.scss';

export const StudyBrownModal = ({ open, closeModal }) => {
  return (
    <div className="study-modal-container">
      <Modal visible={open} onClickAway={() => closeModal()}>
        <div className="study-container">
          <div className="study-header">
            <div class="close" onClick={() => closeModal()}>
              ×
            </div>
          </div>
          <div className="study-content">
            <h2>Study 1 - Brown</h2>
            <p>
              A phase 3, randomized, double-blind, placebo-controlled study to
              evaluate the analgesic efficacy and tolerability of single- and
              multiple-dose SPRIX in major abdominal and orthopedic surgery
              patients remaining in hospital for 2–5 days. Patients were
              randomly assigned in a 2:1 ratio to receive SPRIX 31.5 mg or
              matching placebo following surgery (Day 0). All patients had
              access to morphine sulfate (MS) by patient-controlled analgesia
              (PCA) beginning on Day 0. The study was designed with a multidose
              regimen of SPRIX 31.5 mg or matching placebo administered 3 times
              per day for up to 5 days. Backup analgesia was permitted. Total
              hysterectomies were the majority of abdominal surgery procedures
              (87%). Hip replacements were the most common orthopedic procedures
              (72%). Other procedures were ovarian cystectomy, laminectomy,
              rotator cuff repair, fracture reduction and fixation,
              salpingo-oophorectomy, breast reconstruction, appendectomy, and
              knee and ankle replacement. Morphine use reduction was a secondary
              endpoint.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
